import { useState, useEffect, useContext } from "react";
import { createContext } from "react";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { AuthContext } from "./AuthContext";
import LazyLoading from "../components/lazyloading/LazyLoading";
import { useDispatch, useSelector } from "react-redux";
import {
  setDarkMode,
  setCurrency,
  setDefaultPeriodicDisplay,
  selectPeriodicDisplay,
  initiateUserExpenseCategories,
  initiateUserIncomeCategories,
  resetUserSettingsState,
  addUserExpenseCategory,
  addUserIncomeCategory,
  editUserExpenseCategory,
  editUserIncomeCategory,
  deleteUserExpenseCategory,
  deleteUserIncomeCategory,
  initiateDefaultExpenseCategories,
  initiateDefaultIncomeCategories,
} from "../redux/userSettingsSlice";

const UserSettingsContext = createContext();

export const UserSettingsContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { currentUser, onDemo } = useContext(AuthContext);

  const addCategory = async (type, label, icon, color) => {
    let objectId = "";

    // ----------------------- START: demo mode only ---------------------
    if (!currentUser && onDemo) {
      objectId = Math.random().toString(36);
    }
    // ----------------------- END: demo mode only ---------------------

    if (currentUser && !onDemo) {
      const newDoc = {
        label: label,
        icon: icon,
        color: color,
        backgroundColor: color + "33",
        hidden: false,
        default: false,
        type: type,
        userId: currentUser.uid,
      };
      const newDocRef = doc(collection(db, "userCategories")); // document id
      try {
        await setDoc(newDocRef, newDoc);
        objectId = newDocRef.id;
      } catch (err) {
        return;
      }
    }

    const newCategoryObj = {
      id: objectId,
      label: label,
      icon: icon,
      color: color,
      backgroundColor: color + "33",
      hidden: false,
      default: false,
    };

    if (type === "expense") {
      dispatch(addUserExpenseCategory(newCategoryObj));
    } else if (type === "income") {
      dispatch(addUserIncomeCategory(newCategoryObj));
    }
  };

  const editCategory = async (type, label, icon, color, id) => {
    if (currentUser && !onDemo) {
      try {
        await updateDoc(doc(db, "userCategories", id), {
          label: label,
          color: color,
          backgroundColor: color + "33",
          type: type,
          icon: icon,
        });
      } catch (err) {
        return;
      }
    }
    const newCategoryObj = {
      id: id,
      label: label,
      icon: icon,
      color: color,
      backgroundColor: color + "33",
      hidden: false,
      default: false,
    };
    if (type === "expense") {
      dispatch(editUserExpenseCategory(newCategoryObj));
    } else if (type === "income") {
      dispatch(editUserIncomeCategory(newCategoryObj));
    }
  };

  const editDefaultCategory = async (type, label, icon, color, id, hidden) => {
    const newCategoryObj = {
      id: id,
      label: label,
      icon: icon,
      color: color,
      backgroundColor: color + "33",
      hidden: hidden,
      default: true,
    };

    if (currentUser && !onDemo) {
      try {
        if (type === "expense") {
          await updateDoc(doc(db, "userSettings", currentUser.uid), {
            [`defaultCategories.${id}`]: newCategoryObj,
          });
        } else if (type === "income") {
          await updateDoc(doc(db, "userSettings", currentUser.uid), {
            [`defaultIncomeCategories.${id}`]: newCategoryObj,
          });
        }
      } catch (err) {
        return;
      }
    }

    if (type === "expense") {
      dispatch(editUserExpenseCategory(newCategoryObj));
    } else if (type === "income") {
      dispatch(editUserIncomeCategory(newCategoryObj));
    }
  };

  const deleteCategory = async (type, id) => {
    if (currentUser && !onDemo) {
      try {
        await deleteDoc(doc(db, "userCategories", id));
      } catch (err) {
        return;
      }
    }

    if (type === "expense") {
      dispatch(deleteUserExpenseCategory(id));
    } else if (type === "income") {
      dispatch(deleteUserIncomeCategory(id));
    }
  };

  const currencyOptions = [
    { country: "Albania Lek", code: "ALL", symbol: "Lek" },
    { country: "Argentina Peso", code: "ARS", symbol: "$" },
    { country: "Aruba Guilder", code: "AWG", symbol: "ƒ" },
    { country: "Australia Dollar", code: "AUD", symbol: "$" },
    { country: "Bahamas Dollar", code: "BSD", symbol: "$" },
    { country: "Barbados Dollar", code: "BBD", symbol: "$" },
    { country: "Belarus Ruble", code: "BYN", symbol: "Br" },
    { country: "Belize Dollar", code: "BZD", symbol: "BZ$" },
    { country: "Bermuda Dollar", code: "BMD", symbol: "$" },
    { country: "Bolivia Bolíviano", code: "BOB", symbol: "$b" },
    {
      country: "Bosnia and Herzegovina Convertible Mark",
      code: "BAM",
      symbol: "KM",
    },
    { country: "Botswana Pula", code: "BWP", symbol: "P" },
    { country: "Brazil Real", code: "BRL", symbol: "R$" },
    { country: "Brunei Darussalam Dollar", code: "BND", symbol: "$" },
    { country: "Canada Dollar", code: "CAD", symbol: "$" },
    { country: "Cayman Islands Dollar", code: "KYD", symbol: "$" },
    { country: "Chile Peso", code: "CLP", symbol: "$" },
    { country: "China Yuan Renminbi", code: "CNY", symbol: "¥" },
    { country: "Colombia Peso", code: "COP", symbol: "$" },
    { country: "Costa Rica Colon", code: "CRC", symbol: "¢" },
    { country: "Croatia Kuna", code: "HRK", symbol: "kn" },
    { country: "Czech Republic Koruna", code: "CZK", symbol: "Kc" },
    { country: "Denmark Krone", code: "DKK", symbol: "kr" },
    { country: "Dominican Republic Peso", code: "DOP", symbol: "RD$" },
    { country: "East Caribbean Dollar", code: "XCD", symbol: "$" },
    { country: "Egypt Pound", code: "EGP", symbol: "£" },
    { country: "El Salvador Colon", code: "SVC", symbol: "$" },
    { country: "Euro Member Countries", code: "EUR", symbol: "€" },
    { country: "Falkland Islands (Malvinas) Pound", code: "FKP", symbol: "£" },
    { country: "Fiji Dollar", code: "FJD", symbol: "$" },
    { country: "Ghana Cedi", code: "GHS", symbol: "¢" },
    { country: "Gibraltar Pound", code: "GIP", symbol: "£" },
    { country: "Guatemala Quetzal", code: "GTQ", symbol: "Q" },
    { country: "Guernsey Pound", code: "GGP", symbol: "£" },
    { country: "Guyana Dollar", code: "GYD", symbol: "$" },
    { country: "Honduras Lempira", code: "HNL", symbol: "L" },
    { country: "Hong Kong Dollar", code: "HKD", symbol: "$" },
    { country: "Hungary Forint", code: "HUF", symbol: "Ft" },
    { country: "Iceland Krona", code: "ISK", symbol: "kr" },
    { country: "Indonesia Rupiah", code: "IDR", symbol: "Rp" },
    { country: "Isle of Man Pound", code: "IMP", symbol: "£" },
    { country: "Jamaica Dollar", code: "JMD", symbol: "J$" },
    { country: "Japan Yen", code: "JPY", symbol: "¥" },
    { country: "Jersey Pound", code: "JEP", symbol: "£" },
    { country: "Lebanon Pound", code: "LBP", symbol: "£" },
    { country: "Liberia Dollar", code: "LRD", symbol: "$" },
    { country: "Malaysia Ringgit", code: "MYR", symbol: "RM" },
    { country: "Mexico Peso", code: "MXN", symbol: "$" },
    { country: "Mozambique Metical", code: "MZN", symbol: "MT" },
    { country: "Namibia Dollar", code: "NAD", symbol: "$" },
    { country: "Netherlands Antilles Guilder", code: "ANG", symbol: "ƒ" },
    { country: "New Zealand Dollar", code: "NZD", symbol: "$" },
    { country: "Nicaragua Cordoba", code: "NIO", symbol: "C$" },
    { country: "Norway Krone", code: "NOK", symbol: "kr" },
    { country: "Panama Balboa", code: "PAB", symbol: "B/." },
    { country: "Paraguay Guarani", code: "PYG", symbol: "Gs" },
    { country: "Peru Sol", code: "PEN", symbol: "S/." },
    { country: "Philippine Peso", code: "PHP", symbol: "₱" },
    { country: "Poland Zloty", code: "PLN", symbol: "zl" },
    { country: "Romania Leu", code: "RON", symbol: "lei" },
    { country: "Saint Helena Pound", code: "SHP", symbol: "£" },
    { country: "Singapore Dollar", code: "SGD", symbol: "$" },
    { country: "Solomon Islands Dollar", code: "SBD", symbol: "$" },
    { country: "Somalia Shilling", code: "SOS", symbol: "S" },
    { country: "South Africa Rand", code: "ZAR", symbol: "R" },
    { country: "Sweden Krona", code: "SEK", symbol: "kr" },
    { country: "Switzerland Franc", code: "CHF", symbol: "CHF" },
    { country: "Suriname Dollar", code: "SRD", symbol: "$" },
    { country: "Syria Pound", code: "SYP", symbol: "£" },
    { country: "Taiwan New Dollar", code: "TWD", symbol: "NT$" },
    { country: "Trinidad and Tobago Dollar", code: "TTD", symbol: "TT$" },
    { country: "Tuvalu Dollar", code: "TVD", symbol: "$" },
    { country: "United Arab Emirates Dirham", code: "AED", symbol: "DH" },
    { country: "United Kingdom Pound", code: "GBP", symbol: "£" },
    { country: "United States Dollar", code: "USD", symbol: "$" },
    { country: "Uruguay Peso", code: "UYU", symbol: "$U" },
    { country: "Venezuela Bolívar", code: "VEF", symbol: "Bs" },
    { country: "Zimbabwe Dollar", code: "ZWD", symbol: "Z$" },
  ];

  const handleChangeCurrency = async (code) => {
    const currency = currencyOptions.find((item) => item.code === code);

    // demo mode
    if (!currentUser) {
      dispatch(setCurrency(currency));
      return;
    }

    await updateDoc(doc(db, "userSettings", currentUser.uid), {
      currency: currency,
    });
    dispatch(setCurrency(currency));
  };
  const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];

  const changeDefaultPeriodicDisplay = async (newPeriodicDisplay) => {
    // demo mode
    if (!currentUser) {
      dispatch(setDefaultPeriodicDisplay(newPeriodicDisplay));
      return;
    }

    await updateDoc(doc(db, "userSettings", currentUser.uid), {
      defaultPeriodicDisplay: newPeriodicDisplay,
    });
    dispatch(setDefaultPeriodicDisplay(newPeriodicDisplay));
  };

  const isDarkMode = useSelector((state) => state.userSettings.isDarkMode);
  const handleDarkMode = async () => {
    //demo mode
    if (!currentUser) {
      dispatch(setDarkMode(!isDarkMode));
      return;
    }
    // with existing document
    dispatch(setDarkMode(!isDarkMode));
    await updateDoc(doc(db, "userSettings", currentUser.uid), {
      darkMode: !isDarkMode,
    });
  };

  useEffect(() => {
    dispatch(resetUserSettingsState()); //reset the state

    if (!currentUser) {
      setLoading(false);
      return;
    }

    setLoading(true);
    const fetchData = async () => {
      const q = query(doc(db, "userSettings", currentUser.uid));
      const res = await getDoc(q);
      let customExpenseCategories = [];
      let customIncomeCategories = [];
      let customDefaultExpenseCategories = [];
      let customDefaultIncomeCategories = [];

      const categoriesQuery = query(
        collection(db, "userCategories"),
        where("userId", "==", currentUser.uid)
      );
      const resCategories = await getDocs(categoriesQuery);

      resCategories.forEach((doc) => {
        const { type } = doc.data();

        if (type === "expense") {
          customExpenseCategories.push({ id: doc.id, ...doc.data() });
        } else if (type === "income") {
          customIncomeCategories.push({ id: doc.id, ...doc.data() });
        }
      });

      //if document exist: custom user setting document exist
      if (res.data()) {
        const userSettings = res.data();
        // if currency field exist: currency field exist in the document
        if (userSettings.currency) {
          dispatch(setCurrency(userSettings.currency));
        }
        if (userSettings.darkMode) {
          dispatch(setDarkMode(userSettings.darkMode));
        }
        if (userSettings.defaultPeriodicDisplay) {
          dispatch(selectPeriodicDisplay(userSettings.defaultPeriodicDisplay));
          dispatch(
            setDefaultPeriodicDisplay(userSettings.defaultPeriodicDisplay)
          );
        }
        if (userSettings.defaultCategories) {
          Object.keys(userSettings.defaultCategories).forEach((key) => {
            customDefaultExpenseCategories.push(
              userSettings.defaultCategories[key]
            );
          });
        }
        if (userSettings.defaultIncomeCategories) {
          Object.keys(userSettings.defaultIncomeCategories).forEach((key) => {
            customDefaultIncomeCategories.push(
              userSettings.defaultIncomeCategories[key]
            );
          });
        }
      }

      // dispatch custom categories

      // expense
      if (customDefaultExpenseCategories.length > 0) {
        dispatch(
          initiateDefaultExpenseCategories({
            customDefaultCategories: customDefaultExpenseCategories,
            customUserCategories: customExpenseCategories,
          })
        );
      } else {
        dispatch(initiateUserExpenseCategories(customExpenseCategories));
      }

      //income
      if (customDefaultIncomeCategories.length > 0) {
        dispatch(
          initiateDefaultIncomeCategories({
            customDefaultIncomeCategories: customDefaultIncomeCategories,
            customUserIncomeCategories: customIncomeCategories,
          })
        );
      } else {
        dispatch(initiateUserIncomeCategories(customIncomeCategories));
      }

      setLoading(false);
    };
    fetchData();
  }, [currentUser]);

  return (
    <UserSettingsContext.Provider
      value={{
        months,
        handleChangeCurrency,
        currencyOptions,
        handleDarkMode,
        changeDefaultPeriodicDisplay,
        addCategory,
        editCategory,
        deleteCategory,
        editDefaultCategory,
      }}
    >
      {!loading ? children : <LazyLoading />}
    </UserSettingsContext.Provider>
  );
};

export default UserSettingsContext;
