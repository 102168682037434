import "./budget.scss"
import { useContext, useEffect, useState } from "react"
import RadialChart from "../../components/charts/RadialChart"
import BudgetContext from "../../context/BudgetContext"
import BudgetProgress from "../../components/budgetprogress/BudgetProgress"
import BudgetForm from "../../components/widget/BudgetForm"
import TransactionContext from "../../context/transactionContext"
import { useSelector } from "react-redux"
import PeriodSelection from "../../components/periodselection/PeriodSelection"
import { AnimatePresence, motion } from "framer-motion"

const Budget = () => {
    const userCategories = useSelector((state) => state.userSettings.userCategories)
    const currency = useSelector((state) => state.userSettings.currency)
    const {data} = useContext(TransactionContext)
    const {budgetData} = useContext(BudgetContext)
    const [isEditing, setIsEditing] = useState(false)
    const [editData, setEditData] = useState({})
    const [selectedCategory, setSelectedCategory] = useState("") // used when no existing budget data is selected
    const {year, month, periodicDisplay} = useSelector((state) => state.userSettings.options)
    
    
    const handleEdit = (data, category) => {
        setIsEditing(true)
        setSelectedCategory(category)
        setEditData(data)
      }

      const handleCloseBudgetForm = () => {
        setIsEditing(false)
    }

    const sortedBudgetCategories = [...userCategories].sort((category1, category2) => {
        if (periodicDisplay === "yearly"){
            return (budgetData.find(item => item.category.toUpperCase() === category2.label.toUpperCase())?.totalAmount || 0) - (budgetData.find(item => item.category.toUpperCase() === category1.label.toUpperCase())?.totalAmount || 0)
        } else {
            return (budgetData.find(item => item.category.toUpperCase() === category2.label.toUpperCase())?.amount?.[month] || 0) - (budgetData.find(item => item.category.toUpperCase() === category1.label.toUpperCase())?.amount?.[month] || 0)
        }
    })

    useEffect(()=>{
        document.title = "Expense Aware: Budget"
      },[])
      
  return (
    <div className="budget">
        <h1 className="pageTitle">Budget</h1>
        <div className="gridContainer">
            <div className="left">
                <h1 className="title">Categories</h1>
                <div className="periodTitle mobileOnly">
                        {`${periodicDisplay === "monthly"? month: ""} ${year}`}
                </div>
                <PeriodSelection />
                <div>
                    <AnimatePresence>
                        {sortedBudgetCategories.map((category) =>(
                            !category.hidden &&
                            <motion.div layout key={category.label}>
                                <BudgetProgress category={category}
                                    budgetData={budgetData}
                                    handleEdit={handleEdit}
                                    data={data}
                                    currency={currency}
                                />
                            </motion.div>
                        ))}
                    </AnimatePresence>
                </div>
            </div>
            <div className="right">
                <h1 className="title">Spent</h1>
                <RadialChart budgetData={budgetData}
                    data={data}
                    currency={currency}
                />
            </div>
        </div>
        <AnimatePresence>
            {isEditing &&
                <BudgetForm
                    handleCloseBudgetForm={handleCloseBudgetForm}
                    editData={editData}
                    selectedCategory={selectedCategory}
                    title={`${selectedCategory} budget for ${year}`}
                />
            }
        </AnimatePresence>
        
    </div>
  )
}

export default Budget