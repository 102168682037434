import React, { useEffect, useState } from "react";
import "./accounts.scss";
import AccountsLineChart from "./components/AccountsLineChart";
import AccountCard from "./components/AccountCard";
import { useContext } from "react";
import TransactionContext from "../../context/transactionContext";
import AccountsForm from "./components/AccountsForm";
import { useSelector } from "react-redux";
import { CheckOutlined } from "@mui/icons-material";
import { AnimatePresence } from "framer-motion";

const Accounts = () => {
  const accounts = useSelector((state) => state.accounts.userAccounts);
  const { data, incomeData, otherTransactions } =
    useContext(TransactionContext);

  // ------------------ States ---------------------------------------
  const [userAccounts, setUserAccounts] = useState(accounts);
  const [accountGroups, setAccountGroups] = useState([]);
  const [accountHistory, setAccountHistroy] = useState({
    consolidated: {},
  });
  const [activeAccount, setActiveAccount] = useState("");
  const [showAccountsFormModal, setShowAccountsFormModal] = useState({
    show: false,
    mode: "add",
    editAccount: {},
  });
  const [editingAccounts, setEditingAccounts] = useState(false);
  // ------------------ Handlers ---------------------------------------
  const handleClickAccount = (id) => {
    // do nothing if editing mode
    if (editingAccounts) {
      return;
    }

    if (id !== activeAccount) {
      setActiveAccount(id);
    } else {
      setActiveAccount("");
    }
  };

  const toggleEditingAccounts = () => {
    setEditingAccounts((prevState) => !prevState);

    setActiveAccount("");
  };

  // ------------------ useEffect ---------------------------------------
  // get grouping from accounts
  useEffect(() => {
    const groups = accounts?.reduce((list, item) => {
      list.push(item?.group);
      return list;
    }, []);
    const uniqueGroups = [...new Set(groups)].sort();
    setAccountGroups(uniqueGroups);
    setUserAccounts(accounts);
  }, [accounts]);

  // create data structure for each account transactions
  useEffect(() => {
    let accountHistoryObj = {
      consolidated: {},
    }; // {'consolidated': {01-01-2023: -300, 01-05-2023: 500}, 'asdf212dfk': {01-01-2023: -300, 01-05-2023: 500}}

    const createAccountHistoryObj = (accountObjId, date, amount) => {
      if (!accountHistoryObj[accountObjId]) {
        accountHistoryObj[accountObjId] = {}; //create empty obj
      }
      if (!accountHistoryObj[accountObjId][date?.toLocaleDateString()]) {
        accountHistoryObj[accountObjId][date?.toLocaleDateString()] = {
          amount: 0,
          date: date,
        };
      }
      accountHistoryObj[accountObjId][date?.toLocaleDateString()].amount +=
        amount;
    };

    const createConsolidatedHistoryObj = (date, amount) => {
      if (!accountHistoryObj.consolidated[date?.toLocaleDateString()]) {
        accountHistoryObj.consolidated[date?.toLocaleDateString()] = {
          amount: 0,
          date: date,
        };
      }
      accountHistoryObj.consolidated[date?.toLocaleDateString()].amount +=
        amount;
    };

    const updateAccountHistoryObj = (transaction, type) => {
      const transactionAccountId = transaction.accountId;
      const accountObj = accounts.find(
        (item) => item.id === transactionAccountId
      );

      // proceed only if transaction has a accoundId field
      if (accountObj) {
        const accountBeginningBalanceDate = new Date(
          new Date(accountObj?.beginningBalanceDate?.seconds * 1000).setHours(
            0,
            0,
            0,
            0
          )
        );
        const transactionDate = new Date(
          new Date(transaction.date?.seconds * 1000).setHours(0, 0, 0, 0)
        );
        let amountValue = 0;
        const currentDate = new Date();

        //negative(expense)
        if (type === "expense" || type === "transfer_to") {
          amountValue = -transaction.amount;
        } else if (type === "income" || type === "transfer_from") {
          amountValue = transaction.amount;
        }
        if (
          transactionDate >= accountBeginningBalanceDate &&
          transactionDate <= currentDate
        ) {
          createConsolidatedHistoryObj(transactionDate, amountValue);
          createAccountHistoryObj(accountObj?.id, transactionDate, amountValue);
        }
      }
    };

    data.forEach((transaction) => {
      updateAccountHistoryObj(transaction, "expense");
    });

    incomeData.forEach((transaction) => {
      updateAccountHistoryObj(transaction, "income");
    });

    otherTransactions.forEach((transaction) => {
      const transactionType = transaction.type;
      updateAccountHistoryObj(transaction, transactionType);
    });

    // add account beginning balance to the object
    accounts.forEach((accountObj) => {
      const beginningBalanceDate = new Date(
        accountObj?.beginningBalanceDate?.seconds * 1000
      );
      createConsolidatedHistoryObj(
        beginningBalanceDate,
        accountObj?.beginningBalance
      );
      createAccountHistoryObj(
        accountObj?.id,
        beginningBalanceDate,
        accountObj?.beginningBalance
      );
    });
    setAccountHistroy(accountHistoryObj);
  }, [accounts, incomeData, data, otherTransactions]);

  // update document title
  useEffect(() => {
    document.title = "Expense Aware: My Accounts";
  }, []);

  return (
    <div className="accounts">
      <h1 className="pageTitle">My Accounts</h1>
      <div className="overview">
        <AccountsLineChart
          chartValues={accountHistory}
          activeAccount={activeAccount}
        />
      </div>
      <div className="row">
        <div className="actions">
          {!editingAccounts && (
            <button
              className="add btn-primary"
              onClick={() =>
                setShowAccountsFormModal((prevState) => ({
                  ...prevState,
                  show: true,
                  mode: "add",
                }))
              }
            >
              Add
            </button>
          )}
          <button
            className={`edit ${
              editingAccounts ? "btn-primary" : "expandToggleBtn"
            }`}
            onClick={toggleEditingAccounts}
          >
            {!editingAccounts ? (
              <>Edit Accounts</>
            ) : (
              <CheckOutlined className="icon" />
            )}
          </button>
        </div>
      </div>
      <div className="accountsGroups">
        {accountGroups.map((group, index) => {
          return (
            <div className="accountGroup" key={group + index}>
              <div className="row divider">
                <div className="groupName">{group}</div>
                <hr></hr>
              </div>
              <div className="accountsList">
                {userAccounts
                  .filter((item) => item.group === group)
                  .map((userAccount) => {
                    return (
                      <div className="accountContainer" key={userAccount.id}>
                        <AccountCard
                          activeAccount={activeAccount}
                          userAccount={userAccount}
                          userAccountTransactions={
                            accountHistory[userAccount?.id]
                          }
                          handleClickAccount={handleClickAccount}
                          editingAccounts={editingAccounts}
                          setShowAccountsFormModal={setShowAccountsFormModal}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
      <AnimatePresence>
        {showAccountsFormModal.show && (
          <AccountsForm
            showAccountsFormModal={showAccountsFormModal}
            setShowAccountsFormModal={setShowAccountsFormModal}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default Accounts;
