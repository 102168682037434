import "./pftools.scss";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useEffect } from "react";

const PfTools = () => {
  const { currentUser, onDemo } = useContext(AuthContext);

  useEffect(() => {
    document.title = "Expense Aware: Personal Finance Tools";
  }, []);
  return (
    <div className="pftools">
      <h1 className="pageTitle">Personal Finance Tools</h1>
      <div className="pfToolsList">
        <div className="col">
          <Link
            to={`${
              !currentUser && onDemo ? "/demo" : "/my-dashboard"
            }/pf-tools/loan-calculator`}
          >
            <div className="card">
              <div className="pfToolsItem">
                <div className="top">
                  <div className="title">Loan Calculator</div>
                </div>
                <div className="bottom">
                  <div className="description">
                    A simple loan calculator that determine periodic payments,
                    APR (effective rate), and generate an amortization table.
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col">
          <Link
            to={`${
              !currentUser && onDemo ? "/demo" : "/my-dashboard"
            }/pf-tools/fire-calculator`}
          >
            <div className="card">
              <div className="pfToolsItem">
                <div className="top">
                  <div className="title">
                    Financial Indenpendence, Retire Early (FIRE) Calculator
                  </div>
                </div>
                <div className="bottom">
                  <div className="description">
                    Calculate the possibilities of achieving Financial
                    Independence and Retiring Early. Estimate when you could
                    potentially achieve FIRE.
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PfTools;
