import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userAccounts: [],
}

export const sidebarSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    // [{id:...,}, {id:...}]
    initiateAccounts: (state, action) => {
        state.userAccounts = [...action.payload].sort((account1, account2) => {
          return (account1.name?.toUpperCase() > account2.name?.toUpperCase() ? 1 : -1)
        })
    },
    addAccount: (state, action) => {
        state.userAccounts = [...state.userAccounts, action.payload].sort((account1, account2) => {
          return (account1.name?.toUpperCase() > account2.name?.toUpperCase() ? 1 : -1)
        })
    },
    editAccount: (state, action) => {
      state.userAccounts = [...[...state.userAccounts].filter(account => account.id !== action.payload.id), action.payload].sort((account1, account2) => {
        return (account1.name?.toUpperCase() > account2.name?.toUpperCase() ? 1 : -1)
      })
    },
    removeAccount: (state, action) => {
      state.userAccounts = [...state.userAccounts].filter(account => account.id !== action.payload)
    },
    resetAccountsState: (state) => {
      return initialState;
    },
  },
})


export const { 
    initiateAccounts,
    addAccount,
    editAccount,
    removeAccount,
    resetAccountsState,
} = sidebarSlice.actions

export default sidebarSlice.reducer