import { useEffect } from "react";
import Datatable from "../../components/datatable/Datatable";
import "./transaction.scss";
import { AssignmentTurnedInOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const Transaction = () => {
  useEffect(() => {
    document.title = "Expense Aware: Expenses";
  }, []);
  const { currentUser, onDemo } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleNavigateToProjects = () => {
    navigate(
      `${!currentUser && onDemo ? "/demo" : "/my-dashboard"}/expenses/projects`
    );
  };

  return (
    <div className="transactions">
      <div className="row">
        <h1 className="pageTitle">Expenses</h1>
        <button className="myProjectsButton" onClick={handleNavigateToProjects}>
          <AssignmentTurnedInOutlined className="icon" />
          My Projects
        </button>
      </div>
      <Datatable />
    </div>
  );
};

export default Transaction;
