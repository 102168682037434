import { useEffect, useState } from "react"
import { createContext } from "react"


const MobileContext = createContext();

export const MobileContextProvider = ({children}) => {
    const [width, setWidth] = useState(window.innerWidth);
    let isPWA = false;
    
    if (window.matchMedia('(display-mode: standalone)').matches){
        isPWA = true
    }

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth)
    }


    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 650;

    return(
        <MobileContext.Provider value={{ isMobile, isPWA }}>
            {children}
        </MobileContext.Provider>
    )
}

export default MobileContext