import "./radialchart.scss";
import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getActualTotalFromArray } from "../../utils/utils";

const RadialChart = (props) => {
  const isDarkMode = useSelector((state) => state.userSettings.isDarkMode);
  const { year, month, periodicDisplay } = useSelector(
    (state) => state.userSettings.options
  );

  var chartOptions = {
    chart: {
      height: 350,
      id: "radialBar",
      offsetY: -10,
      fontFamily: "Inter, sans-serif",
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            fontSize: "1rem",
            fontWeight: "400",
            color: undefined,
            offsetY: 120,
          },
          value: {
            offsetY: 76,
            fontSize: "22px",
            color: `${isDarkMode ? "lightgray" : undefined}`,
            formatter: function (val) {
              return val + "%";
            },
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        shadeIntensity: 0.15,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 65, 91],
      },
    },
    stroke: {
      dashArray: 4,
    },
    labels: ["Budget spent"],
  };

  const { budgetData, data } = props;
  const currency = useSelector((state) => state.userSettings.currency);
  const [percTotal, setPercTotal] = useState(0);
  const [totalBudget, setTotalBudget] = useState(0);
  const [totalActual, setTotalActual] = useState(0);

  useEffect(() => {
    const actualTotal = getActualTotalFromArray(
      data,
      month,
      year,
      periodicDisplay
    );
    if (periodicDisplay === "yearly") {
      const total = budgetData
        .filter((item) => item.category !== "Savings")
        .reduce((totalAmount, item) => {
          return (totalAmount += item.totalAmount);
        }, 0);

      const perc = Math.min(
        Math.round(((actualTotal / total) * 100 * 100) / 100),
        100
      );

      if (perc) {
        setPercTotal(perc);
      } else {
        setPercTotal(0);
      }

      setTotalBudget(total);
      setTotalActual(actualTotal);
    } else if (periodicDisplay === "monthly") {
      const total = budgetData
        .filter((item) => item.category !== "Savings")
        .reduce((totalAmount, item) => {
          if (item.amount[month]) {
            return (totalAmount += item.amount[month]);
          } else {
            return totalAmount;
          }
        }, 0);
      const perc = Math.min(
        Math.round(((actualTotal / total) * 100 * 100) / 100),
        100
      );

      if (perc) {
        setPercTotal(perc);
      } else {
        setPercTotal(0);
      }

      setTotalBudget(total);
      setTotalActual(actualTotal);
    }
  }, [budgetData, month, periodicDisplay]);

  return (
    <div className="radialChart">
      <div className="chartWrapper">
        <div className="chartContainer">
          <Chart
            options={chartOptions}
            series={[percTotal]}
            type="radialBar"
            className="radialChart"
          />
        </div>
      </div>

      {/* summary */}
      <hr className="lineBreak" />
      <div className="summaryItem">
        <div className="title">Total Budget</div>
        <div className="amount">
          <span className="currency">{currency.symbol}</span>
          {totalBudget.toLocaleString("en-us")}
        </div>
      </div>
      <div className="summaryItem">
        <div className="title">Spent</div>
        <div className="amount">
          <span className="currency">{currency.symbol}</span>
          {totalActual.toLocaleString("en-us")}
        </div>
      </div>
      <hr className="lineBreak" />
      <div className="summaryItem">
        <div className="title remainingBalance">Left</div>
        <div
          className={`amount remainingBalance ${
            totalBudget - totalActual < 0 ? "negative" : ""
          }`}
        >
          <span className="currency">{currency.symbol}</span>
          {(totalBudget - totalActual).toLocaleString("en-us")}
        </div>
      </div>
    </div>
  );
};

export default RadialChart;
