import { useState } from "react"
import "./tooltip.scss"

const Tooltip = ({text, children}) => {
    const [isVisible, setIsVisible] = useState(false)
  return (
    <div className="tooltip"
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
    >
        {children}
        {isVisible && <div className="tooltipText">{text}</div>}
    </div>
  )
}

export default Tooltip