import { createSlice } from "@reduxjs/toolkit";

const defaultPeriod = "monthly";
const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];
const defaultCategories = [
  {
    id: "1",
    label: "Childcare",
    imageSource: "",
    icon: "ChildFriendlyOutlinedIcon",
    color: "#f6779f",
    backgroundColor: "#e6004933",
    hidden: false,
    default: true,
  },
  {
    id: "2",
    label: "Clothing",
    imageSource: "",
    icon: "CheckroomOutlinedIcon",
    color: "#0bb4ff",
    backgroundColor: "#0bb4ff33",
    hidden: false,
    default: true,
  },
  {
    id: "3",
    label: "Dine Out",
    imageSource: "",
    icon: "RamenDiningOutlinedIcon",
    color: "#CF7474",
    backgroundColor: "#D1494933",
    hidden: false,
    default: true,
  },
  {
    id: "4",
    label: "Donation",
    imageSource: "",
    icon: "VolunteerActivismOutlinedIcon",
    color: "#49d18b",
    backgroundColor: "#49d18b33",
    hidden: false,
    default: true,
  },
  {
    id: "5",
    label: "Electronics",
    imageSource: "",
    icon: "DevicesOutlinedIcon",
    color: "#50D5E0",
    backgroundColor: "#50D5E033",
    hidden: false,
    default: true,
  },
  {
    id: "6",
    label: "Entertainment",
    imageSource: "",
    icon: "LiveTvOutlinedIcon",
    color: "#c6ba00",
    backgroundColor: "#e6d80033",
    hidden: false,
    default: true,
  },
  {
    id: "7",
    label: "Food/Supplies",
    imageSource: "",
    icon: "KitchenOutlinedIcon",
    color: "#9b19f5",
    backgroundColor: "#9b19f533",
    hidden: false,
    default: true,
  },
  {
    id: "8",
    label: "Gifts",
    imageSource: "",
    icon: "CardGiftcardOutlinedIcon",
    color: "#ffa300",
    backgroundColor: "#ffa30033",
    hidden: false,
    default: true,
  },
  {
    id: "9",
    label: "Healthcare",
    imageSource: "",
    icon: "HealthAndSafetyOutlinedIcon",
    color: "#f76868",
    backgroundColor: "#dc0a0a33",
    hidden: false,
    default: true,
  },
  {
    id: "10",
    label: "Housing",
    imageSource: "",
    icon: "CottageOutlinedIcon",
    color: "#67a6f8",
    backgroundColor: "#b3d4ff33",
    hidden: false,
    default: true,
  },
  {
    id: "11",
    label: "Insurance",
    imageSource: "",
    icon: "GppGoodOutlinedIcon",
    color: "#00bfa0",
    backgroundColor: "#00bfa033",
    hidden: false,
    default: true,
  },
  {
    id: "12",
    label: "Personal Care",
    imageSource: "",
    icon: "FaceRetouchingNaturalOutlinedIcon",
    color: "#c18301",
    backgroundColor: "#dc9e3533",
    hidden: false,
    default: true,
  },
  {
    id: "13",
    label: "Pets",
    imageSource: "",
    icon: "PetsOutlinedIcon",
    color: "#9080ff",
    backgroundColor: "#9080ff33",
    hidden: false,
    default: true,
  },
  {
    id: "14",
    label: "Transportation",
    imageSource: "",
    icon: "TrainOutlinedIcon",
    color: "#e27c7c",
    backgroundColor: "#e27c7c33",
    hidden: false,
    default: true,
  },
  {
    id: "15",
    label: "Utilities",
    imageSource: "",
    icon: "ElectricBoltOutlinedIcon",
    color: "#fd7f6f",
    backgroundColor: "#fd7f6f33",
    hidden: false,
    default: true,
  },
  {
    id: "16",
    label: "Others",
    imageSource: "",
    icon: "ReceiptLongOutlinedIcon",
    color: "#a4a2a8",
    backgroundColor: "#a4a2a833",
    hidden: false,
    default: true,
  },
];

const defaultIncomeCategories = [
  {
    id: "1",
    label: "Allowance",
    imageSource: "",
    icon: "VolunteerActivismOutlinedIcon",
    color: "#BD68E3",
    backgroundColor: "#BD68E333",
    hidden: false,
    default: true,
  },
  {
    id: "2",
    label: "Business Income",
    imageSource: "",
    icon: "ApartmentOutlinedIcon",
    color: "#6292E3",
    backgroundColor: "#6292E333",
    hidden: false,
    default: true,
  },
  {
    id: "3",
    label: "Commission",
    imageSource: "",
    icon: "PercentOutlinedIcon",
    color: "#f6779f",
    backgroundColor: "#e6004933",
    hidden: false,
    default: true,
  },
  {
    id: "4",
    label: "Gifts",
    imageSource: "",
    icon: "CardGiftcardOutlinedIcon",
    color: "#f6779f",
    backgroundColor: "#e6004933",
    hidden: false,
    default: true,
  },
  {
    id: "5",
    label: "Interest",
    imageSource: "",
    icon: "CurrencyExchangeOutlined",
    color: "#E07F3A",
    backgroundColor: "#E07F3A33",
    hidden: false,
    default: true,
  },
  {
    id: "6",
    label: "Investments",
    imageSource: "",
    icon: "AddchartOutlinedIcon",
    color: "#50D5E0",
    backgroundColor: "#50D5E033",
    hidden: false,
    default: true,
  },
  {
    id: "7",
    label: "Others",
    imageSource: "",
    icon: "PaidOutlinedIcon",
    color: "#16E0A7",
    backgroundColor: "#16E0A733",
    hidden: false,
    default: true,
  },
  {
    id: "8",
    label: "Salary",
    imageSource: "",
    icon: "WorkOutlineOutlinedIcon",
    color: "#63ad6f",
    backgroundColor: "#2cd74733",
    hidden: false,
    default: true,
  },
];

const sortedCategories = (unsortedArray) => {
  return unsortedArray.sort((category1, category2) => {
    return category1.label?.toUpperCase() > category2.label?.toUpperCase()
      ? 1
      : category2.label?.toUpperCase() > category1.label?.toUpperCase()
      ? -1
      : 0;
  });
};

const initialState = {
  isDarkMode: true,
  currency: { country: "United States Dollar", code: "USD", symbol: "$" },
  defaultPeriodicDisplay: defaultPeriod,
  options: {
    year: new Date().getFullYear().toString(),
    month: months[new Date().getMonth()],
    periodicDisplay: defaultPeriod,
    selectedCategory: "",
  },
  userCategories: defaultCategories,
  userIncomeCategories: defaultIncomeCategories,
};

export const userSettingsSlice = createSlice({
  name: "userSettings",
  initialState,
  reducers: {
    setDarkMode: (state, action) => {
      //action: false
      state.isDarkMode = action.payload;
    },
    setCurrency: (state, action) => {
      //action: {country:"United States Dollar",code:"USD",symbol:"$"}
      state.currency = action.payload;
    },
    setDefaultPeriodicDisplay: (state, action) => {
      //action: "monthly"
      state.defaultPeriodicDisplay = action.payload;
    },
    selectMonthOption: (state, action) => {
      //action: "january"
      state.options.month = action.payload;
    },
    previousPeriod: (state) => {
      if (state.options.periodicDisplay === "monthly") {
        const currentMonthIndex = months.indexOf(state.options.month);
        if (currentMonthIndex === 0) {
          const previousYearFloat = parseFloat(state.options.year) - 1;
          state.options.year = previousYearFloat.toString();
          const previousMonth = months[11];
          state.options.month = previousMonth;
        } else {
          const previousMonthIndex = Math.max(currentMonthIndex - 1, 0);
          const previousMonth = months[previousMonthIndex];
          state.options.month = previousMonth;
        }
      } else if (state.options.periodicDisplay === "yearly") {
        const previousYearFloat = parseFloat(state.options.year) - 1;
        state.options.year = previousYearFloat.toString();
      }
    },
    nextPeriod: (state) => {
      if (state.options.periodicDisplay === "monthly") {
        const currentMonthIndex = months.indexOf(state.options.month);
        if (currentMonthIndex === 11) {
          const nextYearFloat = parseFloat(state.options.year) + 1;
          state.options.year = nextYearFloat.toString();
          const nextMonth = months[0];
          state.options.month = nextMonth;
        } else {
          const nextMonthIndex = Math.min(
            months.indexOf(state.options.month) + 1,
            11
          );
          const nextMonth = months[nextMonthIndex];
          state.options.month = nextMonth;
        }
      } else if (state.options.periodicDisplay === "yearly") {
        const nextYearFloat = parseFloat(state.options.year) + 1;
        state.options.year = nextYearFloat.toString();
      }
    },
    selectPeriodicDisplay: (state, action) => {
      //action: "monthly"
      state.options.periodicDisplay = action.payload;
    },
    changeSelectedCategory: (state, action) => {
      //action: "transportation"
      state.options.selectedCategory = action.payload;
    },
    addUserExpenseCategory: (state, action) => {
      // action: {id: , label: ,icon: , color: ,backgroundColor: ,hidden: false, default: false}
      state.userCategories = sortedCategories([
        ...state.userCategories,
        action.payload,
      ]);
    },
    addUserIncomeCategory: (state, action) => {
      // action: {id: , label: ,icon: , color: ,backgroundColor: ,hidden: false, default: false}
      state.userIncomeCategories = sortedCategories([
        ...state.userIncomeCategories,
        action.payload,
      ]);
    },
    initiateUserExpenseCategories: (state, action) => {
      // action: [{id: , label: ,icon: , color: ,backgroundColor: ,hidden: false, default: false}, ...]
      state.userCategories = sortedCategories([
        ...defaultCategories,
        ...action.payload,
      ]);
    },
    initiateUserIncomeCategories: (state, action) => {
      // action: [{id: , label: ,icon: , color: ,backgroundColor: ,hidden: false, default: false}, ...]
      state.userIncomeCategories = sortedCategories([
        ...defaultIncomeCategories,
        ...action.payload,
      ]);
    },
    initiateDefaultExpenseCategories: (state, action) => {
      // action: {customDefaultCategories: [...], customUserCategories: [...]}
      const updatedDefaultCategories = [...defaultCategories].map(
        (category) => {
          // replace default category to new category
          const newCategory = action.payload.customDefaultCategories.find(
            (item) => category.id === item.id
          );
          if (newCategory) {
            return newCategory;
          }
          return category;
        }
      );

      state.userCategories = sortedCategories([
        ...updatedDefaultCategories,
        ...action.payload.customUserCategories,
      ]);
    },
    initiateDefaultIncomeCategories: (state, action) => {
      // action: {customDefaultIncomeCategories: [...], customUserIncomeCategories: [...]}
      const updatedDefaultCategories = [...defaultIncomeCategories].map(
        (category) => {
          // replace default category to new category
          const newCategory = action.payload.customDefaultIncomeCategories.find(
            (item) => category.id === item.id
          );
          if (newCategory) {
            return newCategory;
          }
          return category;
        }
      );

      state.userIncomeCategories = sortedCategories([
        ...updatedDefaultCategories,
        ...action.payload.customUserIncomeCategories,
      ]);
    },
    editUserExpenseCategory: (state, action) => {
      // action: {id: , label: , icon: , color: , backgroundColor: , hidden: false, default: false}
      state.userCategories = sortedCategories([
        ...[...state.userCategories].filter(
          (category) => category.id !== action.payload.id
        ),
        action.payload,
      ]);
    },
    editUserIncomeCategory: (state, action) => {
      // action: {id: , label: , icon: , color: , backgroundColor: , hidden: false, default: false}
      state.userIncomeCategories = sortedCategories([
        ...[...state.userIncomeCategories].filter(
          (category) => category.id !== action.payload.id
        ),
        action.payload,
      ]);
    },
    deleteUserExpenseCategory: (state, action) => {
      // action: "id"
      state.userCategories = [...state.userCategories].filter(
        (category) => category.id !== action.payload
      );
    },
    deleteUserIncomeCategory: (state, action) => {
      // action: "id"
      state.userIncomeCategories = [...state.userIncomeCategories].filter(
        (category) => category.id !== action.payload
      );
    },
    resetUserSettingsState: (state) => {
      return initialState;
    },
  },
});

export const {
  setDarkMode,
  setCurrency,
  setDefaultPeriodicDisplay,
  selectMonthOption,
  previousPeriod,
  nextPeriod,
  selectPeriodicDisplay,
  changeSelectedCategory,
  addUserExpenseCategory,
  addUserIncomeCategory,
  initiateUserExpenseCategories,
  initiateUserIncomeCategories,
  initiateDefaultExpenseCategories,
  initiateDefaultIncomeCategories,
  editUserExpenseCategory,
  editUserIncomeCategory,
  deleteUserExpenseCategory,
  deleteUserIncomeCategory,
  resetUserSettingsState,
} = userSettingsSlice.actions;

export default userSettingsSlice.reducer;
