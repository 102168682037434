import "./colorpicker.scss"

const ColorPicker = (props) => {
    const {selectedColor, setSelectedColor} = props
    const colors = [
        "#F56565",
        "#ED8936",
        "#ECC94B",
        "#48BB78",
        "#38B2AC",
        "#4299E1",
        "#0BC5EA",
        "#9F7AEA",
        "#ED64A6",
    ]

    const activeColor = selectedColor || "#F56565"
  return (
    <div className='colorpicker'>
        {colors.map(color => {
            return (
                <div key={color} className={`colorBox ${activeColor === color ? "active" : ""}`} style={{backgroundColor: color}} onClick={()=>setSelectedColor(color)}></div>
            )
        })}
    </div>
  )
}

export default ColorPicker