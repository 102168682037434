import { configureStore } from "@reduxjs/toolkit";
import userSettingsReducer from "./userSettingsSlice";
import sidebarReducer from "./sidebarSlice";
import accountsReducer from "./accountsSlice";

export const store = configureStore({
  reducer: {
    userSettings: userSettingsReducer,
    sidebar: sidebarReducer,
    accounts: accountsReducer,
  },
});
