import { CircularProgress } from "@mui/material";
import "./deleteprojectmodal.scss";
import { motion } from "framer-motion";

const DeleteProjectModal = (props) => {
  const { confirmDelete, setConfirmDelete, handleDeleteProject, isLoading } =
    props;
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
      }}
      exit={{
        opacity: 0,
        transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
      }}
      className="confirmDelete"
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
        }}
        exit={{
          scale: 0.95,
          opacity: 0,
          transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
        }}
        className="confirmDeleteContainer"
      >
        <h1>Are you sure you want to delete Project?</h1>
        <h3>{confirmDelete.name}</h3>
        <p>
          Please note that deleting a project will remove the project itself
          from your dashboard, but the records associated with it will still be
          included in your expense records. This means that you can still view
          and analyze these expenses, but they will no longer be organized under
          the specific project.
        </p>
        <div className="row">
          <button
            className="btn-cancel"
            onClick={() => setConfirmDelete({ showMessage: false, id: "" })}
          >
            Cancel
          </button>
          <button
            className="deleteButton"
            disabled={isLoading}
            onClick={() => {
              handleDeleteProject(confirmDelete.id);
              setConfirmDelete({ showMessage: false, id: "" });
            }}
          >
            {isLoading ? (
              <CircularProgress size={"1.275rem"} className="spinner" />
            ) : (
              <>Delete</>
            )}
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default DeleteProjectModal;
