import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showSidebar: false,
  addingProject: false,
}

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
        state.showSidebar = !state.showSidebar
    },
    setSidebarState: (state, action) => {
      state.showSidebar = action.payload
    },
    setAddingProject: (state, action) => {
      state.addingProject = action.payload
    },
    resetSidebarState: (state) => {
      return initialState;
    },
  },
})


export const { 
    toggleSidebar,
    setSidebarState,
    setAddingProject,
    resetSidebarState,
} = sidebarSlice.actions

export default sidebarSlice.reducer