import { useContext } from "react";
import { createContext } from "react";
import { AuthContext } from "./AuthContext";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  addAccount,
  editAccount,
  initiateAccounts,
  removeAccount,
  resetAccountsState,
} from "../redux/accountsSlice";
import {
  Timestamp,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { db } from "../firebase";

const AccountsContext = createContext();

export const AccountsContextProvider = ({ children }) => {
  const { currentUser, onDemo } = useContext(AuthContext);
  const dispatch = useDispatch();

  const createAccount = async (name, group, beginningBalance, date, color) => {
    let objectId = "";
    let userId = "";
    const beginningBalanceFloat = parseFloat(
      beginningBalance?.replace(/,/gi, "")
    );
    // ----------------------- START: demo mode only ---------------------
    if (!currentUser && onDemo) {
      objectId = Math.random().toString(36);
      userId = "test";
    }
    // ----------------------- END: demo mode only ---------------------

    const accountDetails = {
      name: name,
      group: group,
      beginningBalance: beginningBalanceFloat,
      beginningBalanceDate: Timestamp.fromDate(new Date(date)),
      color: color,
    };
    if (currentUser && !onDemo) {
      // new document
      const newDoc = { ...accountDetails, userId: currentUser.uid };
      // document id
      const newDocRef = doc(collection(db, "accounts"));
      try {
        await setDoc(newDocRef, newDoc);
        objectId = newDocRef.id;
        userId = currentUser.uid;
      } catch (err) {
        return;
      }
    }

    // save to state
    const newAccountObj = {
      ...accountDetails,
      id: objectId,
      userId: userId,
      beginningBalanceDate: { ...accountDetails.beginningBalanceDate }, //avoid serializer issue with redux
    };

    dispatch(addAccount(newAccountObj));
  };

  const updateAccount = async (
    accountId,
    name,
    group,
    beginningBalance,
    date,
    color,
    balanceAdjustment
  ) => {
    let userId = "";
    const beginningBalanceFloat = parseFloat(
      beginningBalance?.replace(/,/gi, "")
    );
    // ----------------------- START: demo mode only ---------------------
    if (!currentUser && onDemo) {
      userId = "test";
    }
    // ----------------------- END: demo mode only ---------------------

    const updatedAccountDetails = {
      name: name,
      group: group,
      beginningBalance: beginningBalanceFloat,
      beginningBalanceDate: Timestamp.fromDate(new Date(date)),
      color: color,
    };

    if (currentUser && !onDemo) {
      try {
        await updateDoc(doc(db, "accounts", accountId), updatedAccountDetails);
        userId = currentUser.uid;
      } catch (err) {
        return;
      }
    }

    // save to state
    const newAccountObj = {
      id: accountId,
      userId: userId,
      ...updatedAccountDetails,
      beginningBalanceDate: { ...updatedAccountDetails.beginningBalanceDate }, //avoid serializer issue with redux
    };

    dispatch(editAccount(newAccountObj));
  };

  const deleteAccount = async (id) => {
    if (currentUser && !onDemo) {
      try {
        await deleteDoc(doc(db, "accounts", id));

        // delete transfer transactions
        let operationCounter = 0;
        let batch = writeBatch(db);

        const transactionsQuery = query(
          collection(db, "transactions"),
          where("userId", "==", currentUser.uid),
          where("accountId", "==", id),
          where("type", "in", ["transfer_from", "transfer_to"])
        );
        const transactionsArray = await getDocs(transactionsQuery);

        transactionsArray.forEach(async (document) => {
          const transactionsDocData = document.data();
          batch.delete(document.ref, transactionsDocData);
          operationCounter += 1;

          if ((operationCounter + 1) % 499 == 0) {
            await batch.commit();
            batch = writeBatch(db);
          }
        });

        // final commit
        if (!(operationCounter % 499) == 0) {
          await batch.commit();
        }
      } catch (err) {
        console.log(err);
        return;
      }
    }
    dispatch(removeAccount(id));
  };

  useEffect(() => {
    dispatch(resetAccountsState());
    let unsubscribed = false;
    let accountsList = [];

    // ------------- demo only -------------
    if (!currentUser && onDemo) {
      import("./dummyData.js").then((module) => {
        module.userAccounts.forEach((item) => {
          accountsList.push(item);
        });
        dispatch(initiateAccounts(accountsList));
      });
      return;
    } // ------------- end: demo only -------------

    const fetchData = async () => {
      const q = query(
        collection(db, "accounts"),
        where("userId", "==", currentUser.uid)
      );
      const res = await getDocs(q);

      if (!unsubscribed) {
        res.forEach((document) => {
          accountsList.push({
            id: document.id,
            ...document.data(),
            beginningBalanceDate: { ...document.data()?.beginningBalanceDate },
          });
        });
        dispatch(initiateAccounts(accountsList));
      }
    };

    fetchData();

    return () => {
      unsubscribed = true;
    };
  }, [currentUser]);

  return (
    <AccountsContext.Provider
      value={{ createAccount, updateAccount, deleteAccount }}
    >
      {children}
    </AccountsContext.Provider>
  );
};

export default AccountsContext;
