import { useState, useEffect, useContext } from "react";
import { createContext } from "react";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { AuthContext } from "./AuthContext";

const UserProjectsContext = createContext();

export const UserProjectsContextProvider = ({ children }) => {
  const { currentUser, onDemo } = useContext(AuthContext);
  const [projects, setProjects] = useState([]);

  const addProject = async (projectName) => {
    // ---------------- demo mode only ----------------------------
    if (!currentUser && onDemo) {
      const newDoc = {
        userId: "test",
        name: projectName,
        sheetId: new Date().valueOf().toString(),
      };
      setProjects((prevState) => [
        { ...newDoc, id: new Date().valueOf().toString() },
        ...prevState,
      ]);
      return; //exit function
    } // ---------------- demo mode only ----------------------------

    // document to be submitted
    const newDocRef = doc(collection(db, "userProjects")); // to get Id when submitted and used to update budgetData
    const newDoc = {
      userId: currentUser.uid,
      name: projectName,
      sheetId: newDocRef.id,
    };
    try {
      await setDoc(newDocRef, newDoc);
      setProjects((prevState) => [
        { ...newDoc, id: newDocRef.id },
        ...prevState,
      ]);
    } catch (err) {}
  };

  const deleteProject = async (id) => {
    // ---------------- demo mode only ----------------------------
    if (!currentUser && onDemo) {
      setProjects(projects.filter((item) => item.id !== id));
      return;
    } // ---------------- demo mode only ----------------------------

    try {
      await deleteDoc(doc(db, "userProjects", id));
      setProjects(projects.filter((item) => item.id !== id));
    } catch (err) {}
  };

  const updateProjectName = async (id, projectName) => {
    // ---------------- demo mode only ----------------------------
    if (!currentUser && onDemo) {
      const newObject = {
        id: id,
        userId: "test",
        name: projectName,
      };
      setProjects((prevState) => {
        const newState = prevState.map((obj) => {
          let oldObject = { ...obj };
          if (obj.id === id) {
            return newObject;
          } else {
            return oldObject;
          }
        });
        return newState;
      });
    } // ---------------- demo mode only ----------------------------

    try {
      await updateDoc(doc(db, "userProjects", id), {
        name: projectName,
      });
      const newObject = {
        id: id,
        userId: currentUser.uid,
        name: projectName,
      };
      setProjects((prevState) => {
        const newState = prevState.map((obj) => {
          let oldObject = { ...obj };
          if (obj.id === id) {
            return newObject;
          } else {
            return oldObject;
          }
        });
        return newState;
      });
    } catch (err) {}
  };

  useEffect(() => {
    let list = [];

    // ---------------- demo mode only ----------------------------
    if (!currentUser && onDemo) {
      import("./dummyData.js").then((module) => {
        setProjects(module.dummyProjects);
      });
      return; // exit demo mode function
    } // ---------------- demo mode only ----------------------------

    const fetchData = async () => {
      const q = query(
        collection(db, "userProjects"),
        where("userId", "==", currentUser.uid)
      );
      const res = await getDocs(q);

      res.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });

      setProjects(list);
    };
    fetchData();
  }, [currentUser]);

  return (
    <UserProjectsContext.Provider
      value={{ projects, addProject, deleteProject, updateProjectName }}
    >
      {children}
    </UserProjectsContext.Provider>
  );
};

export default UserProjectsContext;
