import { useState, useEffect, useContext } from "react"
import { createContext } from "react"
import { doc, deleteDoc,collection, onSnapshot, query, orderBy, where, limit, writeBatch, getDocs } from "firebase/firestore";
import { db } from '../firebase';
import { AuthContext } from "./AuthContext";


const TransactionContext = createContext()

export const TransactionContextProvider = ({children}) => {
    const [data, setData] = useState([])
    const [incomeData, setIncomeData] = useState([])
    const [otherTransactions, setOtherTransactions] = useState([])
    const {currentUser, onDemo} = useContext(AuthContext)
    
    const updateTransactionsCategory = async(newCategory, oldCategory, type) => {
        if (currentUser && !onDemo){
            try{
                let operationCounter = 0
                let batch = writeBatch(db)
                const transactionsQuery = query(collection(db, "transactions"), where("userId", "==", currentUser.uid), where("category", "==", oldCategory), where("type", "==", type))
                const transactionsArray = await getDocs(transactionsQuery)

                transactionsArray.forEach(async(document) => {
                    batch.update(document.ref, {"category": newCategory})
                    operationCounter += 1
          
                    if ((operationCounter + 1) % 499 == 0) {
                      await batch.commit()
                      batch = writeBatch(db)
                    }
                  })
        
                // final commit
                if (!(operationCounter % 499) == 0){
                    await batch.commit()
                }
                
            }catch (err){
                console.log(err)
                return
            }
        }
        // ------------------------------------- demo mode only -------------------------------------
        if (!currentUser && onDemo){
            if (type === "expense"){
                setData(prevState => ([...prevState].map(item => {
                    if (item.category === oldCategory){
                        return ({...item, category: newCategory})
                    }
                    return ({...item})
                })))
            }else if (type === "income"){
                setIncomeData(prevState => ([...prevState].map(item => {
                    if (item.category === oldCategory){
                        return ({...item, category: newCategory})
                    }
                    return ({...item})
                })))

            }
        }// ------------------------------------- demo mode only -------------------------------------
    }

    useEffect(() => {

        // ------------------------------------- demo mode only -------------------------------------
        if (!currentUser && onDemo) {
            import("./dummyData.js").then(module => {
                setData(module.dummyTransactions.sort((item1, item2) => item2.date.seconds - item1.date.seconds).filter(item => (item.type === "expense")))
                setIncomeData(module.dummyTransactions.filter(item => (item.type === "income")))
            })
            return
        } //------------------------------------- demo mode only -------------------------------------
        
        const q = query(collection(db, "transactions"), 
        where("userId", "==", currentUser.uid),
        orderBy('date', 'desc'), limit(10000))

        const unsub = onSnapshot(q, (snapShot) => {
            let expenseList = [];
            let incomeList = [];
            let othersList = [];

            snapShot.docs.forEach(doc => {
                const {type} = doc.data()
                const transactionData = {id:doc.id, ...doc.data()}
                if (type === "expense"){
                    expenseList.push(transactionData)
                } else if (type === "income"){
                    incomeList.push(transactionData)
                } else {
                    othersList.push(transactionData)
                }
            });
            // setData(list.filter(item => (item.type === "expense")))
            // setIncomeData(list.filter(item => (item.type === "income")))
            setData(expenseList)
            setIncomeData(incomeList)
            setOtherTransactions(othersList)
        }, (error) => {
        });
        
        return () => {
            unsub();
        }
    }, [])

    return (
        <TransactionContext.Provider value={{data, incomeData, setData, setIncomeData, updateTransactionsCategory, otherTransactions, setOtherTransactions}}>
            {children}
        </TransactionContext.Provider>
    )
}

export default TransactionContext